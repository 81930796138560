import './menuStyle.css';

export const Menu = () => {
    return (
        <div className="menuWrapper">
            <p className='attention'>※価格は2025年4月2日からのものです</p>
            <div className="tanpin">
                <p className="menu">単品メニュー (税込)</p>
                    <article className="grid">
                        <h2 className="menuname">特製もも</h2>
                        <div className="quantityPrice">
                            <h3>100g(3個)</h3>
                            <h3>350円</h3>
                        </div>
                        <p className="img"><img src="/images/tanpin/tokumomo.JPG" width="180" height="180" alt=""/></p>	
                        <p className="text">当店1番人気!! たっぷりのにんにくを使用し香り豊かに仕上げました</p>
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">醤油からあげ</h2>
                        <div className="quantityPrice">	
                            <h3>100g(3個)</h3>
                            <h3>350円</h3>
                        </div>	
                        <p className="img"><img src="/images/tanpin/syouyu.JPG" width="180" height="180" alt=""/></p>
                        <p className="text">旨味のある九州しょうゆを使用した定番からあげ</p>
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">もも辛</h2>
                        <div className="quantityPrice">
                            <h3>100g(3個)</h3>
                            <h3>350円</h3>
                        </div>
                        <p className="img"><img src="/images/tanpin/momokara.JPG" width="180" height="180" alt=""/></p>
                        <p className="text">辛さがやみつき！数種類の香辛料をブレンド、辛さの中にも旨味あり</p>
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">ヘルシーむね</h2>
                        <div className="quantityPrice">
                            <h3>100g(5~6個)</h3>
                            <h3>300円</h3>
                        </div>
                        <p className="img"><img src="/images/tanpin/mune.JPG" width="180" height="180" alt=""/></p>
                        <p className="text">柔らかくてジューシー♪</p>
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">せせり</h2>
                        <div className="quantityPrice">
                            <h3>100g(2~3本)</h3>
                            <h3>380円</h3>
                        </div>
                        <p className="img"><img src="/images/tanpin/seseri.JPG" width="180" height="180" alt=""/></p>
                        <p className="text">首まわりの肉で希少部位。ブリっと弾力のある食感。<font color="red">※入荷がある場合のみ</font></p>
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">手羽塩</h2>
                        <div className="quantityPrice">
                            <h3>100g(4~5本)</h3>
                            <h3>370円</h3>						
                        </div>
                        <p className="img"><img src="/images/tanpin/tebasio.JPG" width="180" height="180" alt=""/></p>
                        <p className="text">カリっと香ばしいチキンバー</p>
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">ずりから</h2>
                        <div className="quantityPrice">
                            <h3>100g(10個前後)</h3>
                            <h3>340円</h3>
                        </div>
                        <p className="img"><img src="/images/tanpin/zurikara.JPG" width="180" height="180" alt=""/></p>
                        <p className = "text">大分からあげ おつまみにも 最適砂ずりのからあげ</p>
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">手羽先</h2>
                        <div className="quantityPrice">
                            <h3>100g(2本)</h3>
                            <h3>330円</h3>
                        </div>
                        <p className="img"><img src="/images/tanpin/tebasaki.JPG" width="180" height="180" alt=""/></p>
                        <p className="text">大分からあげ 食べ応えのある逸品</p>
                    </article>
            </div>
	  	
            <div className="sideMenu">
                <p className="menu">サイドメニュー (税込)</p>
                    <article className="grid">
                        <h2 className = "menuname">フライドポテト</h2>
                        <div className="quantityPrice">
                            <h3>S:200円</h3>
                            <h3>M:320円</h3>
                            <h3>L:420円</h3>
                        </div>
                        <p className="img"><img src="/images/sidemenu/poteto.JPG" width="180" height="180" alt=""/></p>	
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">ゴボウのからあげ</h2>
                        <div className="quantityPrice">
                            <h3>S:280円</h3>
                            <h3>M:560円</h3>
                            <h3>L:840円</h3>
                        </div>
                        <p className="img"><img src="/images/sidemenu/gobo.JPG" width="180" height="180" alt=""/></p>
                        
                    </article>
                    <article className="grid">
                    <h2 className = "menuname">タレから</h2>
                    <div className="quantityPrice">
                        <h3>S:380円</h3>
                        <h3>M:740円</h3>
                    </div>
                        <p className="img"><img src="/images/sidemenu/tarekara.JPG" width="180" height="180" alt=""/></p>
                        <p className="text">おかずに最適! 甘みのある九州醤油と数種類の調味料を煮込んだ特製タレに絡めました</p>
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">チキン南蛮</h2>
                        <div className="quantityPrice">
                            <h3>S:470円</h3>
                            <h3>M:920円</h3>
                        </div>
                        <p className="img"><img src="/images/sidemenu/nanban.JPG" width="180" height="180" alt=""/></p>
                        <p className = "text">自慢の逸品 甘酢、タルタルソースは全て手作り</p>
                    </article>
            </div>
            <div className="bento">
                <p className="menu">お弁当 (税込)<p>※サイズ毎でご飯とからあげの量が異なります</p></p>
                    <article className="grid">
                        <h2 className = "menuname">特製もも弁当</h2>
                        <h3>小盛:480円</h3>
                        <h3>並盛:600円</h3>
                        <h3>大盛:780円</h3>
                        <h3>ドカ盛:1000円</h3>
                        <p className="img"><img src="/images/bento/tokumomo_bento.jpg" width="180" height="180" alt=""/></p>	
                        <p className="text">※写真は小盛</p>
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">醤油からあげ弁当</h2>
                        <h3>小盛:480円</h3>
                        <h3>並盛:600円</h3>
                        <h3>大盛:780円</h3>
                        <h3>ドカ盛:1000円</h3>
                        <p className="img"><img src="/images/bento/syoyu_bento.jpg" width="180" height="180" alt=""/></p>	
                        <p className="text">※写真は大盛</p>
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">もも辛弁当</h2>
                        <h3>小盛:480円</h3>
                        <h3>並盛:600円</h3>
                        <h3>大盛:780円</h3>
                        <h3>ドカ盛:1000円</h3>
                        <p className="img"><img src="/images/bento/momokara_bento.jpg" width="180" height="180" alt=""/></p>	
                        <p classNameName="text">※写真はドカ盛</p>

                    </article>
                    <article className="grid">
                        <h2 className = "menuname">手羽塩弁当</h2>
                        <h3>小盛:480円</h3>
                        <h3>並盛:600円</h3>
                        <h3>大盛:780円</h3>
                        <h3>ドカ盛:1000円</h3>
                        <p className="img"><img src="/images/bento/tebashio_bento.jpg" width="180" height="180" alt=""/></p>	
                        <p className="text">※写真は並盛</p>
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">タレから弁当</h2>
                        <h3>小盛:500円</h3>
                        <h3>並盛:620円</h3>
                        <h3>大盛:800円</h3>
                        <h3>ドカ盛:1050円</h3>
                        <p className="img"><img src="/images/bento/tare_bento.jpg" width="180" height="180" alt=""/></p>	
                        <p className="text">※写真は大盛</p>
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">チキン南蛮弁当</h2>
                        <h3>小盛:550円</h3>
                        <h3>並盛:650円</h3>
                        <h3>大盛:850円</h3>
                        <h3>ドカ盛:1100円</h3>
                        <p className="img"><img src="/images/bento/nanban_bento.jpg" width="180" height="180" alt=""/></p>	
                        <p className="text">※写真は大盛</p>
                    </article>
            </div>
		
            <div className="otherMenu">
                <p className="menu">盛り合わせ (税込)</p>
                    <article className="grid">
                        <h2 className = "menuname">ファミリー盛り</h2>
                        <h3>4~5人前</h3>
                        <h3>3300円</h3>
                        <p className="img"><img src="/images/others/family.jpg" width="180" height="180" alt=""/></p>	
                        <p className="text">特製もも、むね、手羽先、しょうゆ、ずりからの盛り合わせ</p>
                        <p className = "text"></p>
                        
                    </article>
                    <article className="grid">
                        <h2 className = "menuname">オードブル</h2>
                        <h3>3名様以上 3200円</h3>
                        <h3>5名様以上 4300円</h3>
                        <p className="img"><img src="/images/others/odoburu_s.jpg" width="180" height="180" alt=""/></p>	
                        <p className="text">チキン南蛮、タレからあげ、ゴボウのからあげ入り</p>
                    </article>
            </div>
        </div>
    );
    }